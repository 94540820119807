import React from 'react'
import AppstoreBadge from '../images/App_Store_Badge_US.svg'

const AppStoreButton = ({title, url}) => {
    return (
        <a href={url} target={"_blank"} rel="noreferrer" 
            title={title}>
        <img alt={title} src={AppstoreBadge}/></a>
    )
}

export default AppStoreButton
