import React from "react"
import { Link } from 'gatsby'
import Seo from "../../components/SEO"
import Layout from "../../components/bluescope/layout"
import AppStoreBadge from "../../components/AppStoreButton"

import logo from "../../images/bluescope/applogo.svg"
// import seologo from "../../images/bluescope/appicon256.png"
import favicon from "../../images/bluescope/favicon.png"

import seoFeatureImage from "../../images/bluescope/bluescope_seo.png"
import featureImage from "../../images/bluescope/bluescope_header.png"
import imageMyDevices from "../../images/bluescope/mylist.png"
import imageDarkMode from "../../images/bluescope/darkMode.png"
import imageNotifyViews from "../../images/bluescope/notify_views.png"
import imageMapViews from "../../images/bluescope/mapViews.png"
import imagePrivacy from "../../images/bluescope/privacy.png"
import imageTechnology from "../../images/bluescope/technology.png"
import imagePlaySound from "../../images/bluescope/playSound.png"

function BlueScopeIndexPage(props) {
    const seoImage = {
		src: seoFeatureImage,
		height: 256,
		width: 256,
		alt: "BlueScope® App Logo",
    };
    
    const appStoreCampaignUrlBlueScope = "https://apps.apple.com/app/apple-store/id1530096924?pt=121290356&ct=mhome&mt=8"
    const appStoreBadgeTitle = "Download BlueScope® on the App Store";

    return (
    <Layout>
        <Seo
            siteTitle={'BlueScope®'}
            seoTitle={"BlueScope for iOS App"}
            path={props.location.pathname}
            metaImage={seoImage}
            icon={favicon}
            keywords={["BlueScope", "BlueScope ", "Bluetooth® Device Finder", "Bluetooth® Finder", "Bluetooth® Scanner", "BLE Scanner", 
                        "Find My AirPods", "Find AirPods", "Bluetooth® Tracker", "Device Tracker"]}
            description="The Most Advanced Bluetooth® Device Finder, with built-in Privacy and Security. Manage your Bluetooth® Devices in a new way!"
            datePublished="12-13-2020"
            dateModified="05-08-2023"
            is_app={true}
            appName={"BlueScope"}
            appId={"1530096924"}
        />

        <div className={"page-header home"}>
            <br /><br />
            <img className={"applogo"} alt={"Logo"} src={logo}/>
            <br /><br />

            <h1 style={{marginBottom: 0, color: '#0398F4'}}>BlueScope®</h1>
            {/* <h2 style={{color: '#0398F4'}}>for iOS</h2> */}
            <h3 className={"subtitle"} style={{color: '#0398F4'}}>
                Advanced Bluetooth® Tracker
                {/* Next-Gen Bluetooth® Scope with Privacy and Security - Monitor, Manage and Find your Bluetooth® Devices */}
            </h3>

            <br />
            <div className={"features"} style={{padding: "2rem"}}>
                <p>
                    BlueScope® is the most advanced Bluetooth® Tracker & Device Finder on the App Store.
                    Manage, track and monitor your Bluetooth® Devices like never before.
                </p>
            </div>
                
            <br /><br />
            <img className={"featureImage"} alt={"BlueScope Feature Image"} src={featureImage}/>
            <br /><br /><br />
            <p>Download on the App Store today!</p><br />
            <p>
                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
            </p>
        </div>

        <div className={"container"}>
            <div className={"features"}>
              <h1 className={"feature-title"}>Features</h1>
              
              <div className={"feature__item"}>
                <div className={"row"}>
                    <div className={"col-6 first"}>
                        <div className={"thumbnail"}>
                            <img style ={{maxWidth: "100%"}} alt={"BlueScope My Device List"} src={imageMyDevices}/>
                        </div>
                    </div>

                    <div className={"col-6"}>
                        <div className={"feature__content"}>
                            <h2>Smart Device Identification<sup>1</sup></h2>
                            <p>BlueScope® can automatically identify devices and accurately display device symbols and model name<sup>2</sup>. It can also display current battery level of the device, if the device supports it. View and manage all of your Bluetooth® devices at one place. </p>
                        </div>

                        <div className={"store-button"}>                
                            <br />
                            <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                        </div>

                    </div>
                </div>
              </div>


              <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Lost & Found Notifications<sup>1</sup></h2>
                                <h3 style={{paddingBottom: "0px", marginBottom: "0px"}}>Lost Notifications</h3>
                                <p>Built-in Device Tracker lets you monitor your personal Bluetooth® devices to get immediate alert notifications when your device goes out of range.</p>
                                <br />
                                <p>Easily set notifications with one touch.</p>
                                <br />
                                <h3 style={{paddingBottom: "0px", marginBottom: "0px"}}>Found Notifications</h3>
                                <p>Device Tracker also allows receiving alert notifications when a lost Bluetooth® device is detected nearby.</p>
                                <br />
                                <p>
                                <h3 style={{paddingBottom: "0px", marginBottom: "0px"}}>Background Notifications</h3>
                                Receive Lost & Found notifications even when the app is not running in the foreground.
                                </p>
                                <br />
                                <h3 style={{paddingBottom: "0px", marginBottom: "0px"}}>Apple Watch Support</h3>
                                <p>Now you can receive Lost & Found notifications on your paired Apple Watch.</p>
                            </div>
                            
                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "100%"}} alt={"BlueScope Notifications feature"} src={imageNotifyViews}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "100%"}} alt={"BlueScope Map and Directions"} src={imageMapViews}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Maps & Locations<sup>1</sup></h2>
                                <p>View device location in Map View whether the device is within range or offline.</p>
                                <br />
                                <p>With Location Services enabled, you can see the last known location of your Bluetooth® device when it's out of range. With added notifications, receive automatic alerts when the device is detected nearby.</p>
                                <br />
                                <h3 style={{paddingBottom: "0px", marginBottom: "0px"}}>Directions to Lost Device</h3>
                                <br />
                                <p>
                                    Built-in support for Apple Maps and Google Maps lets you get directions to the last known location of your lost device, if location services were enabled earlier.
                                </p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                            </div>

                        </div>
                    </div>
                </div>

                 <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Play Sound on Device<sup>1</sup></h2>
                                <p>BlueScope® also allows you to Play Sound on the supported devices, to easily locate them. When a supported device is detected and connected, a speaker icon is displayed below the device information. Tap on 'Play Sound' to play sound on the device.</p>
                                <br />
                                <h3 style={{paddingBottom: "0px", marginBottom: "0px"}}>Siri Support</h3>
                                <p>
                                    With added support for Siri Shortcuts, you can simply say, "Hey Siri, Find AirPods" or "Find My AirPods"
                                </p>
                                <br />
                                <h3 style={{paddingBottom: "0px", marginBottom: "0px"}}>Apple AirPods</h3>
                                <p>
                                    All models of Apple AirPods are supported, in addition to other supported (BLE) Bluetooth® audio devices like headphones, BLE speakers, etc.
                                </p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"BlueScope Play Sound feature"} src={imagePlaySound}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                    <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Dark Mode Support</h2>
                                <p>BlueScope® comes with complete support for dark mode with native iOS design. With added support for custom colors, it's easy on the eyes, with high contrast and beautiful true-to-the-app blue color scheme. Simple, elegant and beautiful!</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "100%"}} alt={"BlueScope Dark Mode support"} src={imageDarkMode}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "100%"}} alt={"BlueScope Technology"} src={imageTechnology}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Advanced Technology</h2>
                                <p>BlueScope® also supports BLE 5.0 (and later) devices, and is powered by Modern Technology & Advanced Algorithms, to give you a great user experience, while providing fantastic and easy to use features.</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                            </div>

                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                    <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Privacy and Security</h2>
                                <p>BlueScope® is built with your privacy and security in mind and doesn't collect any of the Bluetooth® device information. All data related to any of the detected Bluetooth® devices stays on your iOS device and never transferred to our servers. Read more about <Link to="/bluescope/privacy">BlueScope® privacy policy</Link>.</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img style ={{maxWidth: "80%"}} alt={"BlueScope Privacy"} src={imagePrivacy}/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Accessibility</h2>
                                <p>Accessible first design focuses on making software usable for all. ActionTimer supports high contrast, reduced motion, inverse colors, and voice over support. 
                                <br /><br />ActionTimer also has full keyboard support for easy and quick operation.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Users"} src={thumbnailAcessibility}/>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
            
        </div>

        <div className={"call-to-action"}>
            {/* <div>
                <p>
                    <img className={"desktop-img"} alt={"BlueScope logo"} src={logo}/>
                </p>
            </div> */}

            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>BlueScope®</h2>
                    <h4 style={{paddingTop: 0, color: "rgb(255, 255, 255)"}}>Advanced Bluetooth® Tracker</h4>
                    <p>Download on the App Store today !!</p>
                </div>

                <div className={"store-button"}>                
                    <AppStoreBadge title={appStoreBadgeTitle} url={appStoreCampaignUrlBlueScope} />
                </div>

                
            </div>
        </div>
        <div className={"container"}>
            <div className={"footnotes"}>
                <ol>
                    <li>
                        Premium features require an active Premium Subscription.
                    </li>
                    <li>
                        Currently, only Apple devices are supported.
                    </li>
                </ol>
            </div>
            <div className={"notes"}>
                <ul>
                    <li>BlueScope® is a registered trademark of Malaud LLC.</li>
                    <li>Bluetooth® is a registered trademark of Bluetooth SIG.</li>
                    <li>
                        Apple®, iOS®, iPhone®, iPad®, App Store®, Apple Watch®, Apple Maps®, AirPods®, Siri® are registered trademarks of Apple Inc.
                    </li>
                </ul>      
            </div>

        </div>

        {/* <div className={"container"}>
        </div> */}
    </Layout>
    )
}

export default BlueScopeIndexPage
